
.ListItem{
    display: flex;
    width: 340px;
    padding: 5px 15px;
    text-align: left;
    border-bottom: dotted 1px var(--primary-color);
    margin: 10px;
    justify-content: space-between;
}

.ItemName{
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 18px;
    white-space: nowrap;

}
.ItemQuantity{
    text-align: left;
    width: 100px;
    white-space: nowrap;
}
.ItemIcons{
    transition: 300ms;
    cursor: pointer;
    margin: auto 5px;
    display: flex;
    align-items: center;
}

.ItemTools{
    display: flex;
    align-items: center;
    width: 40%;
}

.ItemIcons:hover{
    transition: 300ms;
    transform: scale(1.1);

}

.ItemIcon:active{
    transition: 300ms;
    transform: scale(0.9);
}

.itembuyed{
    display: flex;
    font-weight: 700;
    justify-content: center;
    align-items: center;
}
