.NewItemContainer{
    width: 340px;
    height: fit-content;
    background: linear-gradient(264.89deg, #86E4FD 0%, var(--primary-color) 100%);
    box-shadow: 0px 3px 7px 2px rgba(71, 200, 236, 0.5);
    border-radius: 21px;
    margin: 10px;
    color: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    margin-top: 20px;
    transition: 300ms;
}

.NewItemForm{
    height: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    font-size: 14px;
}

.NewItemplusicon{
    
    transition: 300ms;
    height: 30px;
    width: 30px;
    cursor: pointer;
}
.Newitemplusicon:active{
    transition: 300ms;
    transform: scale(0.9);
}
.NewItemplusicon:hover{
    transition: 300ms;
    transform: scale(1.1);
}
.NewItemplusicon img{
    height: 100%;
    width: 100%;
}
.NewItemInput{
    margin-top: 5px;
    width: 100px;
    height: 32px;
    border: 1px solid #30A4AB;
    box-sizing: border-box;
    border-radius: 7px;
    outline: #444; 
    padding: 10px; 
}

.NewItemSelect{
    background-color: #FFF;
    margin-top: 5px;
    width: 100px;
    height: 32px;
    border: 1px solid #30A4AB;
    box-sizing: border-box;
    border-radius: 7px;
    padding-left: 5px ;
    font-weight: 500;
}


/* Estilização da lista de itens */
.ListItemsContainer{
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.ListHeader{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 2rem auto;
    gap: 1rem;
}

.CategoryHeader{
    width: 100%;
    text-align: left;
    font-size: 23px;
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.CategoryHeader img{
   width: 23px;
   height: 23px;
}


