.subtitle {
  max-width: 340px;
  margin: 20px auto;
}

.ListSelect{
  display: flex;
  gap: 1rem;
  margin: 10px;
  justify-content: flex-end;
}

.ListSelectItem{
  cursor: pointer;
}

.ListSelectItem:hover{
  opacity: 0.5;
}