.CreatedListContainer {
  transition: 300ms;
  display: flex;
  width: 340px;
  height: fit-content;
  flex-direction: column;
  border-radius: 21px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin: 10px;
  margin-top: 10px;
  padding: 10px 15px;
  cursor: pointer;
  transition: 300ms;
  gap: 1rem;
}

.listLinkContainer {
  display: flex;
  width: 80%;
  height: 100%;
  text-align: left;
  align-items: center;
  justify-content: space-between;
}

.bin {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35%;
}

.bin:active {
  transition: 300ms;
  transform: scale(0.9);
}

.bin:hover {
  transition: 300ms;
  transform: scale(1.1);
}


.CreatedListHeader{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.CreatedListShareListContainer{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1rem;
}

.CreatedListShareListContainer input{
  width: 100%;
  height: 32px;
  border: 1px solid #30A4AB;
  box-sizing: border-box;
  border-radius: 7px;
  outline: #444; 
  padding: 10px; 
}

.SharedList{
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
}



.list-0 {
    background: linear-gradient(264.89deg, #86e4fd 0%, var(--primary-color) 100%);
    box-shadow: 0px 4px 4px rgba(71, 200, 236, 0.5);
    border-radius: 21px;
  }
  
.list-1 {
    background: linear-gradient(264.89deg, #f3abc4 0.11%, #f25786 99.59%);
    box-shadow: 0px 4px 4px #f3abc4;
    border-radius: 21px;
  }
  
.list-2 {
    background: linear-gradient(264.89deg, #9fb4ff 0%, #ac43ff 100%);
    box-shadow: 0px 4px 4px #A1AAFF;
    border-radius: 21px;
  }
  
.list-3 {
    background: linear-gradient(264.89deg, #ffd29f 0%, #ffa843 100%);
    box-shadow: 0px 4px 4px #ffd29f;
    border-radius: 21px;
}
