.CreateAccountContainer {
  height: 80vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.ShowPassowordIcon{
  position: absolute;
  top: 30px;
  right: 15px;
  cursor: pointer;
}

.ShowPassowordIcon:hover{
  opacity: 0.7;
}