.EmailConfirmationModalContainer{
    position: absolute;
    bottom: 10px;
    width: 340px;
    height: fit-content;
    background: linear-gradient(264.89deg, #f3abc4 0.11%, #f25786 99.59%);
    box-shadow: 0px 4px 4px #f3abc4;
    border-radius: 21px;
    margin: 10px;
    color: #FFF;
    
    
    padding: 15px;
  
    margin-top: 20px;
}

.EmailConfirmationModalBox{
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.EmailConfirmationModalMessage{
    font-size: 10px;
    font-weight: bold;
}

.EmailConfirmationModalEmail{
    font-size: 12px;
    font-weight: bold;
    color: #000;
}

.EmailConfirmationModalButton{
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
    cursor: pointer;
}