
.ProfileModalCard{
    position: absolute;
    max-width: 90vh;
    min-width: 300px;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 200px;
    background: linear-gradient(264.89deg, #EEE 0%, #FFF 100%);
    box-shadow: 0px 4px 4px rgba(167, 167, 167, 0.5);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 10px;
    z-index: 20;
    flex-direction: column;
}

.ProfileModalHeader{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.ProfileMenuCloseButton{
    cursor: pointer;
    transition: 300ms;
}

.ProfileMenuCloseButton:hover{
    transition: 300ms;
    transform: scale(1.1);
}


.ProfileModalBody{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.ProfileModalImage{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    box-shadow: 0px 2px 4px rgba(71, 200, 236, 0.5);
}

.ProfileModalName{
    font-size: 15px;
}

.ProfileModalEmail{
    font-size: 12px;
}