.NewListContainer{
    width: 340px;
    height: 93px;
    background: linear-gradient(264.89deg, #86E4FD 0%, var(--primary-color) 100%);
    box-shadow: 0px 3px 7px 2px rgba(71, 200, 236, 0.5);
    border-radius: 21px;
    margin: 10px;
    color: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    margin-top: 20px;
}

.newListForm{
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-evenly;
}
.Newlistplusicon{
    cursor: pointer;
    transition: 300ms;
    height: 44px;
    width: 44px;
}
.Newlistplusicon:active{
    transition: 300ms;
    transform: scale(0.9);
}
.Newlistplusicon:hover{
    transition: 300ms;
    transform: scale(1.1);
}
.Newlistplusicon img{
    height: 100%;
    width: 100%;
}
.NewlistInput{
    
    width: 180px;
    height: 32px;
    border: 1px solid #30A4AB;
    box-sizing: border-box;
    border-radius: 7px;
    outline: #444; 
    padding: 10px; 
}