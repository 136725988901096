.header{
    display: flex;
    margin: 10px;
    align-items: center;
    font-size: 30px;
    justify-content: space-between;
}
.logo{
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.LogoutButton{
    margin-left: auto;
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
}

.Row{
    display: flex;
    justify-content: center;
    align-items: center;
}