.ProFileMenuContainer{
    position: relative;
}

.MenuButton{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    border: 1px solid var(--primary-color);
    box-shadow: 0px 2px 4px rgba(71, 200, 236, 0.5);
    cursor: pointer;
    transition: 300ms;
    
}

.MenuButton:hover{
    transition: 300ms;
    opacity: 0.8;
    box-shadow: 0px 2px 4px rgba(24, 72, 85, 0.5);
}

.MenuContainer{
    position: absolute;
    width: fit-content;
    height: fit-content;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: bold;
    align-items: flex-end;
    margin-top: 5px;
    background: linear-gradient(264.89deg, #EEE 0%, #FFF 100%);
    box-shadow: 0px 4px 4px rgba(167, 167, 167, 0.5);
    padding: 1rem;
    border-radius: 10px;
    left: -12px;
}


.MenuContainer *:hover {
    text-shadow: 0px 2px 4px rgba(163, 163, 163, 0.5);
    cursor: pointer;
}